@charset "UTF-8";
@import "variables";

/* CSS Document */
@font-face {
  font-family: 'Work Sans';
  font-style: bold;
  font-weight: 600;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
//  -webkit-appearance: none;
  /*font-feature-settings : "palt";*/
}

html {
  font-size: 62.5%;
}

body {
  font-family: fot-tsukuardgothic-std, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "Yu Gothic", YuGothic, 'Hiragino Kaku Gothic ProN','メイリオ',Meiryo,'ＭＳ Ｐゴシック', sans-serif;
  font-size: 16px;
  /* letter-spacing: 0.02em;*/
  line-height: 1.8;
  background: #f5f6f6;
  color: #222f53;
  text-align: center;
  scrolling: yes;
  position: relative;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
}

*.en {
  font-family: "Work Sans", Helvetica, Arial, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif !important;
  font-weight: 500;
}

body.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
}

a {
  color: #222f53;
  text-decoration: none;
  overflow: hidden;
  outline: none;
  cursor: pointer;
}

a[href^="tel"] {
  color: #222f53 !important;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
  color: #222f53;
}

p {
  font-size: 1.5rem;
}

img {
  border: none;
  vertical-align: top;
}

table {
  border-collapse: collapse;
}

ul {
  list-style-type: none;
}

button, input, select, textarea {
//  border: none;
//  border-radius: 0;
//  -webkit-appearance: none;
}

button {
  cursor: pointer;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

h1, h2, h3 {
  font-weight: bold;
}

h1.en, h2.en, h3.en {
  font-weight: 600;
}

/* ::::::::::::::::::::::::::::::::: COMMON ::::::::::::::::::::::::::::::::: */
.videoBg {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.videoBg video {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: block;
  background: #000;
}

.wrap {
  overflow: hidden;
  width: 100%;
  position: relative;
  z-index: 2;
}

.slick-list {
  _zoom: 1;
  overflow: hidden;
  padding: 0 !important;
}

.pageLoadWrap {
  background: #222f53;
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 2000;
  transition: width 1s cubic-bezier(0.77, 0, 0.175, 1) 0.3s;
  overflow: hidden;
}

.pageLoadWrap .inner {
  position: absolute;
  width: 100vw;
  height: 170px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.pageLoadWrap .inner p.logo {
  padding: 0 0 30px;
}

.pageLoadWrap .inner span.load {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(136, 136, 136, 0);
  border-top-color: rgba(136, 136, 136, 0.7);
  border-radius: 50%;
  animation: spinLoad 0.6s linear 0s infinite;
  display: block;
  margin: 0 auto;
}

html.wf-active .pageLoadWrap,
html.loading-delay .pageLoadWrap {
  width: 0;
}

@keyframes spinLoad {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* HEADER */
header.mainHeader {
  position: fixed;
  width: 100%;
  height: 80px;
  background: #FFF;
  left: 0;
  top: 0;
  z-index: 200;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 0.2s ease;
}

header.mainHeader .inner {
  padding: 20px 30px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header.mainHeader .inner p.siteTitle {
  transition: all 0.4s ease;
  padding: 0 1% 0 0;
}

header.mainHeader .inner h1 {
  font-size: 1.2rem;
  line-height: 20px;
  text-align: left;
  width: calc(100% - 915px);
  max-height: 40px;
  overflow: hidden;
  font-weight: normal;
}

header.mainHeader .menuWrap {
  display: flex;
  width: 660px;
  height: 30px;
  justify-content: space-between;
  text-align: center;
}

header.mainHeader .menuWrap ul.mainList {
  width: 460px;
  display: flex;
  justify-content: space-between;
}

header.mainHeader .menuWrap ul.mainList li {
  padding: 5px 0;
  position: relative;
}

header.mainHeader .menuWrap ul.mainList li a {
  position: relative;
  height: 20px;
  display: block;
  overflow: hidden;
}

header.mainHeader .menuWrap ul.mainList li a span {
  display: block;
  line-height: 20px;
}

header.mainHeader .menuWrap ul.mainList li a span.en {
  font-size: 1.5rem;
  transition: margin 0.3s ease;
}

header.mainHeader .menuWrap ul.mainList li a span.ja {
  font-size: 1.4rem;
  font-weight: bold;
}

header.mainHeader .menuWrap ul.mainList li a:hover span.en {
  margin: -20px 0 0;
}

header.mainHeader .menuWrap ul.mainList li.on::after {
  width: 22px;
  position: absolute;
  content: "";
  border-bottom: 2px solid #222f53;
  border-top: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

header.mainHeader .menuWrap ul.snsList {
  padding: 5px 0;
}

header.mainHeader .menuWrap ul.snsList li {
  display: inline-block;
  padding: 0 0 0 10px;
}

header.mainHeader .menuWrap p.blog {
  width: 80px;
}

header.mainHeader .menuWrap p.blog a {
  position: relative;
  height: 28px;
  border: 1px solid #222f53;
  display: block;
  overflow: hidden;
}

header.mainHeader .menuWrap p.blog a span {
  display: block;
  line-height: 28px;
}

header.mainHeader .menuWrap p.blog a span.en {
  font-size: 1.5rem;
  transition: margin 0.3s ease;
}

header.mainHeader .menuWrap p.blog a span.ja {
  font-size: 1.4rem;
  font-weight: bold;
}

header.mainHeader .menuWrap p.blog a:hover span.en {
  margin: -28px 0 0;
}

@media screen and (max-width: 1250px) {
  header.mainHeader .inner h1 {
    display: none;
  }
}

/* MAIN CONTENT */
.mainContent.page {
  background: #FFF;
  margin-top: 80px;
}

.mainContent .commonWidth {
  width: 1020px;
  margin-left: auto;
  margin-right: auto;
}

/* FOOTER */
footer.mainFooter .bnWrap {
  background: #1e2738;
  padding: 50px 9% 20px;
}

footer.mainFooter .bnWrap .inner {
  width: 1060px;
  padding: 0 0 30px;
  margin: 0 auto;
  _zoom: 1;
  overflow: hidden;
  position: relative;
}

footer.mainFooter .bnWrap ul.bnList {
  width: 1060px;
  _zoom: 1;
  overflow: hidden;
  float: left;
}

footer.mainFooter .bnWrap ul.bnList li {
  width: 250px;
  float: left;
  padding: 0 20px 0 0;
}

footer.mainFooter .bnWrap ul.bnList li:last-child {
  padding: 0;
}

footer.mainFooter .bnWrap ul.bnList li img {
  width: 100%;
  height: auto;
}

footer.mainFooter .bnWrap ul.slick-dots {
  height: 7px;
  width: 100%;
  left: 0;
  bottom: 7px;
  position: absolute;
}

footer.mainFooter .bnWrap ul.slick-dots li {
  display: inline-block;
  padding: 0 3px;
  width: 7px;
  height: 7px;
  line-height: 7px;
  vertical-align: top;
}

footer.mainFooter .bnWrap ul.slick-dots li button {
  width: 7px;
  height: 7px;
  line-height: 7px;
  text-indent: -999px;
  overflow: hidden;
  border-radius: 50%;
  background: #535d79;
  cursor: pointer;
}

footer.mainFooter .bnWrap ul.slick-dots li.slick-active button {
  background: #DDD;
}

footer.mainFooter .breadcrumbs {
  background: #FFF;
}

footer.mainFooter .breadcrumbs .inner {
  padding: 14px 9%;
  text-align: left;
}

footer.mainFooter .breadcrumbs ul {
  font-size: 1.3rem;
  display: block;
  width: 100%;
}

footer.mainFooter .breadcrumbs ul li {
  display: inline;
  padding: 0 5px 0 20px;
  background: url(../img/common/breadcrumbs_page.svg) no-repeat left top;
  background-size: 12px 12px;
}

footer.mainFooter .breadcrumbs ul li:first-child {
  background-image: url(../img/common/breadcrumbs_home.svg);
}

footer.mainFooter .breadcrumbs ul li a {
  text-decoration: underline;
}

footer.mainFooter .breadcrumbs ul li a:hover {
  opacity: 0.7;
}

footer.mainFooter .menuWrap {
  background: #222f53;
  background: rgba(34, 47, 83, 0.8);
}

footer.mainFooter .menuWrap h2 {
  font-size: 1.2rem;
  text-align: left;
  font-weight: normal;
  color: #FFF;
  padding: 40px 4% 0 9%;
}

footer.mainFooter .menuWrap .inner {
  padding: 40px 4% 65px 9%;
  text-align: left;
  _zoom: 1;
  overflow: hidden;
  position: relative;
}

footer.mainFooter .menuWrap .block1 {
  width: 52%;
  float: left;
  display: flex;
}

footer.mainFooter .menuWrap .block1 .line1 {
  width: 38%;
}

footer.mainFooter .menuWrap .block1 .line2 {
  width: 47%;
}

footer.mainFooter .menuWrap .block1 .line3 {
  width: 15%;
}

footer.mainFooter .menuWrap .block1 p.title {
  font-size: 1.8rem;
  padding: 0 0 15px;
  font-weight: bold;
}

footer.mainFooter .menuWrap .block1 p.title a:hover {
  text-decoration: underline;
}

footer.mainFooter .menuWrap .block1 ul {
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0 0 15px;
}

footer.mainFooter .menuWrap .block1 ul li {
  padding: 0 0 3px;
}

footer.mainFooter .menuWrap .block1 ul li a {
  display: inline-block;
  padding: 0 0 0 25px;
  position: relative;
  transition: all 0.2s ease;
}

footer.mainFooter .menuWrap .block1 ul li a::before {
  position: absolute;
  width: 10px;
  height: 1px;
  border-top: 1px solid #FFF;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  content: "";
  transition: all 0.2s ease;
}

footer.mainFooter .menuWrap .block1 ul li a:hover {
  opacity: 0.8;
}

footer.mainFooter .menuWrap .block1 ul li a:hover::before {
  width: 18px;
}

footer.mainFooter .menuWrap .block2 {
  width: 35%;
  padding: 0 0 0 5%;
  float: right;
  border-left: 1px solid #FFF;
}

footer.mainFooter .menuWrap .block2 p {
  font-size: 1.4rem;
  padding: 0 0 10px;
}

footer.mainFooter .menuWrap .block2 p.footerLogo {
  padding: 0 0 25px;
}

footer.mainFooter .menuWrap .block2 p.companyName {
  font-weight: bold;
  font-size: 1.8rem;
  padding: 0 0 3px;
}

footer.mainFooter .menuWrap .block2 p.tel {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.4;
  padding: 0;
}

footer.mainFooter .menuWrap .block2 p.tel span {
  font-size: 3.0rem;
}

footer.mainFooter .menuWrap .block2 p.tel span a[href^="tel:"] {
  color: #FFF !important;
}

footer.mainFooter .menuWrap .block2 p.btn {
  width: 220px;
  font-size: 1.8rem;
  padding: 30px 0 0;
}

footer.mainFooter .menuWrap .block2 p.btn a {
  display: block;
  background: #FFF;
  color: #222f53;
  text-align: center;
  line-height: 60px;
  font-weight: bold;
}

footer.mainFooter .menuWrap .block3 {
  position: absolute;
  left: 9%;
  bottom: 65px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 460px;
}

footer.mainFooter .menuWrap .block3 .left ul {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer.mainFooter .menuWrap .block3 .left ul li:first-child {
  width: 110px;
}

footer.mainFooter .menuWrap .block3 .right {
  padding: 0 0 0 40px;
}

footer.mainFooter .menuWrap .block3 .right ul {
  font-size: 1.4rem;
  width: 260px;
}

footer.mainFooter .menuWrap .block3 .right ul li {
  display: inline-block;
  padding: 0 10px 0 0;
  margin: 0 10px 0 0;
  border-right: 1px solid #DDD;
  line-height: 1.2;
}

footer.mainFooter .menuWrap .block3 .right ul li:last-child {
  border: none;
}

footer.mainFooter .menuWrap .block3 .right ul li a {
  text-decoration: underline;
}

footer.mainFooter .menuWrap .block3 .right ul li a:hover {
  opacity: 0.8;
}

footer.mainFooter .menuWrap .block4 {
  width: 130px;
  height: 130px;
  position: absolute;
  right: 2%;
  bottom: 30px;
}

footer.mainFooter .menuWrap p,
footer.mainFooter .menuWrap a {
  color: #FFF;
}

footer.mainFooter .copyright {
  background: #1e2738;
}

footer.mainFooter .copyright p {
  text-align: right;
  color: #FFF;
  font-size: 1.2rem;
  line-height: 60px;
  padding: 0 30px;
}

/* FOR SMALL PC OR TABLET DEVICE */
@media screen and (max-width: 1150px) {
  footer.mainFooter .menuWrap .block2 p.btn {
    width: 190px;
  }
  footer.mainFooter .menuWrap .block4 {
    width: 110px;
    height: 110px;
    position: absolute;
    bottom: 40px;
  }
  footer.mainFooter .menuWrap .block4 img {
    width: 110px;
    height: 110px;
  }
  .mainContent .commonWidth {
    width: 960px;
  }
  footer.mainFooter .bnWrap .inner {
    width: 860px;
  }
  footer.mainFooter .bnWrap ul.bnList {
    width: 860px;
  }
  footer.mainFooter .bnWrap ul.bnList li {
    width: 200px;
  }
}

/* ::::::::::::::::::::::::::::::::: PC ::::::::::::::::::::::::::::::::: */
@media screen and (min-width: 1000px) {
  .sp {
    display: none !important;
  }
  .hoverAlpha {
    transition: opacity 0.2s ease;
  }
  .hoverAlpha:hover {
    opacity: 0.8;
  }
}

/* ::::::::::::::::::::::::::::::::: SP ::::::::::::::::::::::::::::::::: */
@media screen and (max-width: 999px) {
  .pc {
    display: none !important;
  }
  .pageLoadWrap .inner {
    height: 145px;
  }
  .pageLoadWrap .inner p.logo {
    padding: 0 0 30px;
  }
  .pageLoadWrap .inner p.logo img {
    width: 120px;
    height: 96px;
  }
  /* HEADER */
  header.mainHeader {
    height: 60px;
  }
  header.mainHeader .inner {
    padding: 15px 15px;
    height: 30px;
  }
  header.mainHeader .inner p.siteTitle img {
    width: 138px;
    height: 30px;
  }
  header.mainHeader .menuWrap {
    display: none;
  }
  button.spMenuBtn {
    width: 60px;
    height: 60px;
    position: fixed;
    right: 1.5%;
    top: 0;
    z-index: 1000;
    border: none;
    background: none;
  }
  button.spMenuBtn span {
    width: 20px;
    position: absolute;
    left: 20px;
    border-top: 2px solid #222f53;
    transition: all 0.3s ease;
  }
  button.spMenuBtn span.line1 {
    top: 23px;
  }
  button.spMenuBtn span.line2 {
    top: 30px;
  }
  button.spMenuBtn span.line3 {
    top: 37px;
  }
  button.spMenuBtn.on span {
    border-color: #FFF;
  }
  button.spMenuBtn.on span.line1 {
    width: 22px;
    left: 19px;
    transform: rotate(45deg);
    top: 30px;
  }
  button.spMenuBtn.on span.line2 {
    opacity: 0;
  }
  button.spMenuBtn.on span.line3 {
    width: 22px;
    left: 19px;
    transform: rotate(-45deg);
    top: 30px;
  }
  .spMenuWrap .menuBase {
    position: fixed;
    z-index: 900;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #222f53;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease;
  }
  .spMenuWrap.on .menuBase {
    visibility: visible;
    opacity: 1;
  }
  .spMenuWrap .inner {
    padding: 15px 5%;
    text-align: left;
  }
  .spMenuWrap .menuBlock {
    padding: 55px 5% 40px;
  }
  .spMenuWrap .menuBlock a {
    color: #FFF;
  }
  .spMenuWrap .menuBlock p.title {
    padding: 0 0 15px;
  }
  .spMenuWrap .menuBlock p.title span {
    display: block;
  }
  .spMenuWrap .menuBlock p.title span.en {
    font-size: 3.2rem;
    line-height: 1.1;
  }
  .spMenuWrap .menuBlock p.title span.ja {
    font-size: 1.1rem;
  }
  .spMenuWrap .menuBlock ul.child {
    padding: 0 0 20px 30px;
    font-size: 1.4rem;
  }
  .spMenuWrap .menuBlock ul.child li {
    padding: 0 0 5px 30px;
    background: url(../img/common/vector_right_white.svg) no-repeat left 7px;
    background-size: 20px 10px;
  }
  .spMenuWrap .menuBlock ul.sns {
    text-align: right;
    padding: 15px 0 0;
  }
  .spMenuWrap .menuBlock ul.sns li {
    display: inline-block;
    padding: 0 0 0 10px;
  }
  .spMenuWrap .menuBlock p.title.on,
  .spMenuWrap .menuBlock ul.child li.on {
    opacity: 0.5;
  }
  /* MAIN CONTENT */
  .mainContent.page {
    margin-top: 60px;
  }
  .mainContent .commonWidth {
    width: 90%;
  }
  /* FOOTER */
  footer.mainFooter .bnWrap {
    padding: 25px 7% 10px;
  }
  footer.mainFooter .bnWrap .inner {
    padding: 0 0 30px;
    width: 100%;
  }
  footer.mainFooter .bnWrap ul.bnList {
    width: 100%;
    flex-wrap: wrap;
  }
  footer.mainFooter .bnWrap ul.bnList li {
    max-width: inherit;
    width: calc(50% - 10px);
    padding: 0 5px 10px;
  }
  footer.mainFooter .bnWrap ul.bnList li:last-child {
    padding: 0 5px 10px;
  }
  footer.mainFooter .bnWrap ul.slick-dots {
    bottom: 15px;
  }
  footer.mainFooter .breadcrumbs .inner {
    padding: 14px 5%;
  }
  footer.mainFooter .menuWrap h2 {
    padding: 35px 5% 0 5%;
  }
  footer.mainFooter .menuWrap .inner {
    padding: 25px 5% 30px 5%;
  }
  footer.mainFooter .menuWrap .block1 {
    width: 100%;
    float: none;
    flex-wrap: wrap;
    padding: 0 0 25px;
  }
  footer.mainFooter .menuWrap .block1 p.title {
    font-size: 1.5rem;
    padding: 0 0 5px;
    width: 33%;
  }
  footer.mainFooter .menuWrap .block2 {
    width: 100%;
    padding: 30px 0 0;
    float: none;
    border: none;
    border-top: 1px solid #FFF;
  }
  footer.mainFooter .menuWrap .block2 p.footerLogo {
    padding: 0 0 15px;
  }
  footer.mainFooter .menuWrap .block2 p.footerLogo img {
    width: 184px;
    height: 40px;
  }
  footer.mainFooter .menuWrap .block2 p.companyName {
    font-size: 1.5rem;
    padding: 0 0 5px;
  }
  footer.mainFooter .menuWrap .block2 p.btn {
    width: 100%;
    padding: 20px 0 35px;
  }
  footer.mainFooter .menuWrap .block3 {
    position: relative;
    left: 0;
    bottom: 0;
    display: block;
    width: auto;
  }
  footer.mainFooter .menuWrap .block3 .left {
    width: 100%;
    padding: 0 0 15px;
  }
  footer.mainFooter .menuWrap .block3 .left ul {
    width: 180px;
  }
  footer.mainFooter .menuWrap .block3 .left ul li:first-child {
    width: 100px;
  }
  footer.mainFooter .menuWrap .block3 .right {
    padding: 0;
  }
  footer.mainFooter .menuWrap .block3 .right ul {
    width: auto;
  }
  footer.mainFooter .menuWrap .block4 {
    width: 65px;
    height: 65px;
    right: 4%;
    bottom: 60px;
  }
  footer.mainFooter .menuWrap .block4 img {
    width: 65px;
    height: 65px;
  }
  footer.mainFooter .copyright p {
    text-align: center;
    padding: 0;
  }
}

@import "content";
