@charset "UTF-8";
@import "variables";



/* =========================================================
content （パーツ類）
========================================================= */
.mainContent {
  text-align: left;
  img {
    max-width: 100%;
  }
  
  .secTitle {
    margin-bottom: 50px;
    @include sp {
      margin-bottom: 30px;
    }
    .en {
      display: block;
      line-height: 1;
      font-weight: 600;
      font-size: 5.8rem;
      @include sp {
        font-size: 4.2rem;
      }
    }
    .jp {
      display: block;
      font-size: 2rem;
      @include sp {
        font-size: 1.5rem;
      }
    }
    &:only-child {
      margin-bottom: 0;
    }
  }
  .jpTitle {
    margin-bottom: 40px;
    line-height: 1.5;
    font-size: 3.2rem;
    @include sp {
      margin-bottom: 25px;
      font-size: 2rem;
    }
    &.center {
      text-align: center;
    }
  }
}

section {
  .inner {
    padding: 85px 0;
    @include sp {
      padding: 40px 0;
    }
  }
}


/* =========================================================
タイトル
========================================================= */
.pageTopWrap {
  padding: 80px 0;
  @include sp {
    padding: 50px 0;
  }
  .inner {
    text-align: left;
  }
  h1 span,
  .pageTitle span {
    display: block;
    &.en {
      font-size: 6.6rem;
      font-weight: 600;
      line-height: 1;
      @include sp {
        font-size: 5.0rem;
        line-height: 1.2;
      }
    }
    &.ja {
      font-size: 2.0rem;
      font-weight: bold;
      @include sp {
        font-size: 1.5rem;
      }
    }
  }
  .pageTitle {
    .icon {
      &.sp {
        @include sp {
          vertical-align: middle;
          display: inline-block;
          + .ja {
            display: inline-block;
          }
        }
      }
    }
  }
}


/* =========================================================
ボタン
========================================================= */
.btn {
  max-width: 320px;
  font-size: 1.8rem;
  padding: 0 0;
  a {
    display: block;
    background: #222f53;
    color: #FFF;
    text-align: center;
    line-height: 60px;
    font-weight: bold;
    transition: all 0.3s ease;
    &[target="_blank"]::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      background: url("../img/common/icon_external.svg") no-repeat center / 8px auto;
      width: 8px;
      height: 8px;
      margin: 0 0 3px 8px;
    }
  }
  &.center {
    margin: 0 auto;
  }
}


/* =========================================================
テーブル
========================================================= */
.table01 {
  box-sizing: border-box;
  width: 100%;
  th,
  td {
    vertical-align: top;
    border: 1px solid #4f5976;
    padding: 12px 20px;
    font-weight: bold;
    font-size: 1.5rem;
    @include sp {
      box-sizing: border-box;
      display: block;
      width: 100%;
      padding: 10px 12px;
    }
  }
  th {
    background: $navy;
    width: 25%;
    color: #fff;
    @include sp {
      border-top: none;
      border-bottom: none;
      width: 100%;
    }
  }
}


/* =========================================================
背景
========================================================= */
.bgGray {
  background-color: $gray;
}


/* =========================================================
otherService
========================================================= */
.otherService {
  .inner {
    padding: 80px 0;
    @include sp {
      padding: 40px 0;
    }
  }
  &List {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include sp {
      display: block;
    }
    li {
      width: calc(33.33% - 20px);
      text-align: center;
      font-weight: bold;
      font-size: 1.8rem;
      margin-top: 40px;
      @include pc {
        &:nth-child(-n + 3) {
          margin-top: 0;
        }
      }
      @include sp {
        width: 100%;
        margin-top: 20px;
        font-size: 1.6rem;
      }
      a {
        position: relative;
        display: block;
        overflow: visible;
        img {
          display: block;
          margin-bottom: 15px;
          transition: all .5s ease;
          @include sp {
            margin-bottom: 10px;
          }
        }
        &::after {
          background: #FFF url(../img/common/vector_right.svg) no-repeat center center;
          background-size: 30px 15px;
          width: 30px;
          height: 15px;
          padding: 12px 15px;
          right: 0;
          top: 142px;
          content: "";
          position: absolute;
          transition: right 0.5s ease;
          @include sp {
            content: none;
          }
        }
        &:hover {
          img {
            transform: scale(0.92);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
          }
          &::after {
            right: -8px;
          }
        }
      }
    }
  }
}

.blue {
  color: #3570a7;
}
.green {
  color: #4f9364;
}
.yellow {
  color: #daa742;
}
.pink {
  color: #8b5e80;
}
.beige {
  color: #9e714e;
}
.blueGreen {
  color: #2e6e82;
}


